<template>
  <v-app>
    <v-row class="text-center justify-center white--text mt-12">
      <v-col cols="auto">
        <v-card>
          <v-card-title>Target File Summary Screen</v-card-title>
          <v-list-item-content
            v-for="(item, i) in results"
            :key="i"
          >
            <v-list-item-subtitle><span class="font-weight-medium">TargetFile: </span>{{ $route.query.sc }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">Records Within File: </span>{{ item.uploaded }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">AMA Records Matched: </span>{{ item.amaRecords }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">APN Records Matched: </span>{{ item.apnRecords }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">AAPA Records Matched: </span>{{ item.aapaRecords }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-card-subtitle>Only unique records are imported.</v-card-subtitle>
          <v-btn
            class="mb-5"
            color="primary"
            @click="$router.push({ name: 'TargetData' })"
          >
            Back to Main
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { nowwService } from '@/shared/services'

export default {
  name: 'TargetDataSummary',

  props: {
    amaSelected: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      resultsLoading: false,
      results: []
    }
  },

  created () {
    this.getTargetDataSummary()
    this.scrollTop()
  },

  methods: {
    scrollTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        })
      }, 0)
    },
    async getTargetDataSummary () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getTargetDataSummary(_this.$route.query.sid)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp.result
          }
        })
    }
  }
}
</script>
